import { useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  Box,
  SvgIcon,
  Typography,
  Stack,
  Divider,
  Tabs,
  Tab,
  Button
} from "@mui/material";

import { ReactComponent as Logo } from "../../logo.svg";
import { useAppDispatch } from "../../redux/store";
import { logout } from "../../redux/features/userSlice";
import { toast } from "react-toastify";




const pages = [
  {
    text: "Dashboard",
    link: "/dashboard",
  },

  // {
  //   text: "SubscriberList",
  //   link: "/subscribelist",
  // },

  // {
  //   text: "Content Management",
  //   link: "/content/edit",
  // },
  // {
  //   text: "Price Configurator",
  //   link: "/config/prices",
  // },

  // {
  //   text: "API Configurator",
  //   link: "/config/api",
  // },
  {
    text: "Prompt Configurator",
    link: "/config/prompts",
  },
  {
    text: "Product Configurator",
    link: "/config/products",
  },
];

const AdminHeader = () => {
  const [value, setValue] = useState(0);
  const navigate = useNavigate();

  const dispatch = useAppDispatch();


  const handleLogout = () => {
    localStorage.setItem("logged_in", "false");
    //console.log("loggedin:" + localStorage.getItem("logged_in"));
    dispatch(logout());
    var module = localStorage.getItem("module");
    console.log("module111...." + module);
   
    toast.success("Logout success");
    localStorage.removeItem("payment");
    localStorage.removeItem("msg");
    localStorage.removeItem("complexity");
    localStorage.removeItem("module");
    localStorage.removeItem("subscription_level");
    localStorage.removeItem("free_plan");
    localStorage.removeItem("userRole");


    window.location.href = "https://www.rohanhall.com/code-genie-ai-smart-contract-auditor";
      //navigate("/Anycode_landing");

    
  }
  return (
    <>
      <div className="adminhead">
        <Box sx={{ padding: "16px 32px" }}>
          <Stack flexDirection="row" gap={'75px'}>
            <Stack gap="8px" flexDirection="row">
              <SvgIcon
                sx={{
                  mr: 1,
                  width: 44,
                  height: 44,
                }}
              >
                <Logo />
              </SvgIcon>
              <Typography
                variant="h6"
                noWrap
                component="a"
                href="/"
                sx={{
                  mr: 2,
                  display: { xs: "none", md: "flex" },
                  fontWeight: 700,
                  color: "inherit",
                  textDecoration: "none",
                  alignItems: "center",
                }}
              >
                CodeGenie
              </Typography>
            </Stack>
            <Tabs
              onChange={(e: React.SyntheticEvent, newValue: number) =>
                setValue(newValue)
              }
              value={value}

            >
              {pages.map((page, index) => (
                <Tab
                  key={`admin_tab_${index}`}
                  label={page.text}
                  id={`admin-tab-${index}`}
                  sx={{
                    textTransform: "none",
                    "&.Mui-selected": { color: "#155bab" },
                    color: "black"
                  }}
                  onClick={() => navigate(`/admin${page.link}`)}
                />
              ))}
            </Tabs>
           
            <Stack>
              <Button
                sx={{
                  background: "linear-gradient(270deg, #4BA5EB 0%, #0168B5 100%)",
                  marginLeft: 0,
                  color: "white",
                  paddingX: 2,
                  paddingY: 1
                }}
                onClick={handleLogout}
              >
                Logout
              </Button>
            </Stack>
          </Stack>
        </Box>
        <Divider />
      </div>
      {/* <Divider /> */}
    </>
  );
};

export default AdminHeader;
