import { useEffect, useState } from "react";
import {
  Box,
  Typography,
  Divider,
  Container,
  Stack,
  SvgIcon,
  TextField,
  Button,
  Grid,
  Checkbox,
  Autocomplete,
  FormControl
} from "@mui/material";
import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { LoadingButton } from "@mui/lab";
import { KeyboardBackspace, KeyboardArrowLeft, KeyboardArrowRight, SettingsPowerTwoTone } from "@mui/icons-material";
import { object, string, TypeOf, array, number } from "zod";
import { BrowserView, MobileView, isBrowser, isMobile } from 'react-device-detect';
import {
  SubmitHandler,
  useForm,
  FormProvider,
  Controller,
} from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { toast } from "react-toastify";
import img54 from '../assets/newimg/logo.png';
import {
  useGetPaymentListQuery
} from "../redux/api/paymentApi";
import { useNavigate } from "react-router-dom";
import { useAppDispatch } from "../redux/store";
import { IPaymentDetails, IPaymentHistory } from "../redux/api/types";
import { useUpdatePassMutation } from "../redux/api/authApi";
import { logout } from "../redux/features/userSlice";
import {
  useGetUserDetailsQuery
} from "../redux/api/genieApi";
import { useGetPricesDetailsQuery } from "../redux/api/productApi";
import { useSubscribeNewsMutation } from "../redux/api/authApi";
import { useUnSubscribepaymentMutation } from "../redux/api/authApi";


const newsSubscribeform = object({
  email: string()
    .min(1, "Email address is required")
    .email("Email address is invalid"),
  status: string(),
})

export type NewsSubscriptionForm = TypeOf<typeof newsSubscribeform>


const saveSchema = object({
  product_name: string().min(1, "Product name is required").optional(),
  product_module: string().optional(),
  module_description: string().optional(),
  source_check: array(string()).optional(),
  source_text: string().optional(),
  source_image: string().optional(),
  source_url: string().optional(),
  input_box_title: string().optional(),
  input_box_description: string().optional(),
  export_check: array(string()).optional(),
  export_word: string().optional(),
  export_pdf: string().optional(),
  export_text: string().optional(),
  id: string().optional(),
});

export type ProductSettingSaveInput = TypeOf<typeof saveSchema>;



const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    // backgroundColor: '#1377C3', 
    color: 'lightblack',
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: 'white', // Set background color for odd rows to white
    color: 'black', // Set text color for odd rows to black
  },
  '&:nth-of-type(even)': {
    backgroundColor: '#F2F6FA', // Set background color for even rows to gray
    color: 'darkslategray',
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));

const updatepasswordform = object({
  email: string(),
  oldPassword: string(),
  password: string(),
  passwordConfirm: string(),
})


export type updatepasswordval = TypeOf<typeof updatepasswordform>


const Usersetting = () => {


  console.log('isMobile=>', isMobile);
  console.log('MobileView=>', MobileView);

  const navigate = useNavigate();

  const [subscriptionDate, setSubscriptionDate] = useState(null);
  const [planDetails, setPlanDetails] = useState<string | null>(null);
  const [openMenu, setOpenMenu] = useState("Change Password");
  const useremail = localStorage.getItem('user_email');
  const [updateVal, setUpdateVal] = useState<updatepasswordval>({ email: useremail || '', oldPassword: '', password: '', passwordConfirm: '' })
  const [updatepassMutation] = useUpdatePassMutation();
  const userDet = useGetUserDetailsQuery({ email: useremail || '' });




  useEffect(() => {
    if (userDet.isSuccess) {
      const subscriptionDate = userDet.data.userdet?.subscription_date;

      if (subscriptionDate && Object.keys(subscriptionDate).length > 0) {
          setSubscriptionDate(userDet.data.userdet["subscription_date"].split("T")[0]);
          var user_price_id = userDet.data.userdet["price_id"]
          for (let i = 0; i < userDet.data.userdet["matchedPlanDetails"].length; i++) {
            if ('plan_details' in userDet.data.userdet["matchedPlanDetails"][i]) {
              //console.log(userDet.data.userdet["matchedPlanDetails"][i]["plan_details"].length);
              for (let j = 0; j < userDet.data.userdet["matchedPlanDetails"][i]["plan_details"].length; j++) {
                var price_id = userDet.data.userdet["matchedPlanDetails"][i]["plan_details"][j]["price_id"];
                if (price_id == user_price_id) {
                  setPlanDetails(userDet.data.userdet["matchedPlanDetails"][i]["plan_details"][j]["plan_name"])
                }

              }
            }
          
          //console.log(userDet.data.userdet["matchedPlanDetails"][1]["plan_details"].length);

        }
      }
      else{
        setSubscriptionDate(null);
        setPlanDetails("NA");
      }

    }

  }, [userDet]);

  const [unSubsctiptionpaymentMutation, unSubscriptionState] =  useUnSubscribepaymentMutation();


  // console.log(userDet.data["userdet"]["subscription_date"])

  const [paymentList, setPaymentList] = useState<IPaymentDetails[] | undefined>(undefined);
  const [currentPage, setCurrentPage] = useState(1);
  const pageSize = isMobile ? 5 : 10;

  const { data, isSuccess, isError } = useGetPaymentListQuery({ email: useremail || '', skip: (currentPage - 1) * pageSize, limit: pageSize });

  useEffect(() => {
    setPaymentList(data?.payments);
  }, [data])

  useEffect(() => {
    if (data && data?.payments) {
      const paymentHistory = data?.payments.map((payment, index) => ({
        ...payment,
        serialNumber: (currentPage - 1) * pageSize + index + 1,
      }));
      setPaymentList(paymentHistory);


    }
  }, [data, currentPage]);

  // Pagination
  let totalPages = Math.ceil((data?.totalrecords ?? 0) / pageSize);

  const handleNextPage = () => {
    setCurrentPage((prevPage) => Math.min(prevPage + 1, totalPages));
  }

  const handlePrevPage = () => {
    setCurrentPage((prevPage) => Math.max(prevPage - 1, 1))
  }

  const generatePageNumbers = (totalPages: number, currentPage: number): (number | '...')[] => {
    const pageNumbers: (number | '...')[] = [];
    for (let i = 1; i <= totalPages; i++) {
      if (i === 1 || i === totalPages || Math.abs(i - currentPage) < 3) {
        pageNumbers.push(i);
      } else if (pageNumbers[pageNumbers.length - 1] !== '...') {
        pageNumbers.push('...');
      }
    }
    return pageNumbers;
  };

  const pageNumbers = generatePageNumbers(totalPages, currentPage);
  //

  const [Errors, setErrors] = useState<updatepasswordval>({
    email: '',
    oldPassword: '',
    password: '',
    passwordConfirm: '',
  });

  const customInputStyle = {
    color: 'black',
    border: Errors.oldPassword || Errors.password || Errors.passwordConfirm ? '1px solid red' : '1px solid gray',
  };

  /* const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setUpdateVal({ ...updateVal, [name]: value });
  }; */

  const validateForm = () => {
    const newErrors: updatepasswordval = {
      email: '',
      oldPassword: '',
      password: '',
      passwordConfirm: '',
    };

    // Implement your custom validation logic here


    if (updateVal.oldPassword.trim() === '') {
      newErrors.oldPassword = 'Password is required';
    } else if (updateVal.oldPassword.length < 8) {
      newErrors.oldPassword = 'Password must be at least 8 characters';
    }

    if (updateVal.password.trim() === '') {
      newErrors.password = 'Password is required';
    } else if (updateVal.password.length < 8) {
      newErrors.password = 'Password must be at least 8 characters';
    }

    if (updateVal.passwordConfirm.trim() === '') {
      newErrors.passwordConfirm = 'Password is required';
    } else if (updateVal.passwordConfirm !== updateVal.password) {
      newErrors.passwordConfirm = 'Passwords must match';
    }

    setErrors(newErrors);

    // Check if there are any errors
    return Object.values(newErrors).every((error) => error === '');
  };



  const handleUpdatePassword = () => {

    debugger;

    const isFormValid = validateForm();

    if (isFormValid) {
      updatepassMutation(updateVal)
        .then((response: any) => {
          if ('data' in response) {
            console.log(response)
            toast.success(response.data.message);
            setUpdateVal({
              email: '',
              oldPassword: '',
              password: '',
              passwordConfirm: '',
            });
          } else {

            if (response.error && response.error.data) {
              toast.error(response.error.data.detail)

            }
            else {
              toast.error("Somethng went wrong..")
            }
            console.error('Error sending query:', response.error);
          }
        })
        .catch((error) => {
          console.error('Error sending query:', error);

        });
    }
  }

  const handleCancel = () => {
    setUpdateVal({
      email: '',
      oldPassword: '',
      password: '',
      passwordConfirm: '',
    });
  }

  const dispatch = useAppDispatch();

  const handleLogout = () => {
    localStorage.setItem("logged_in", "false");
    //console.log("loggedin:" + localStorage.getItem("logged_in"));
    dispatch(logout());
    var module = localStorage.getItem("module");
    console.log("module111...." + module);
    window.location.href = "https://www.rohanhall.com/code-genie-ai-smart-contract-auditor";
    toast.success("Logout success");
    localStorage.removeItem("payment");
    localStorage.removeItem("msg");
    localStorage.removeItem("complexity");
    localStorage.removeItem("module");
    localStorage.removeItem("subscription_level");
    localStorage.removeItem("free_plan");
    localStorage.removeItem("userRole");

  }


  
  const handleLearnMore = () =>{
    navigate('/codegenie/smart_contracts');
  }

  
 const [subscribeVal, setSubscribeVal] = useState<NewsSubscriptionForm>({ email: '', status: '' })
  const [newsSubsctiptionMutation, newsSubscriptionState] = useSubscribeNewsMutation();
  

  const methods = useForm<NewsSubscriptionForm>({
    resolver: zodResolver(newsSubscribeform),
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = methods;

  const onSubmitHandler: SubmitHandler<NewsSubscriptionForm> = (values) => {
    newsSubsctiptionMutation(values);
  };


  const handleSubscribeNews = () => {
    newsSubsctiptionMutation(subscribeVal)
      .unwrap()
      .then((response) => {
        console.log('news subscription=>', response);
          const status = response.status;
        
          console.log(status);
          if (status === 'success') {
            toast.success(response.message);
            setSubscribeVal({
              email: '',
              status: ''
            });
          }
      })
      .catch((error) => {
        toast.error(error?.data?.detail ?? 'Something went wrong!');
        console.error('Error sending query:', error);

      });
  }

  
  const handleunsubscribepayment = () => {
    unSubsctiptionpaymentMutation({email: useremail || ''})
      .then((response) => {
        if ('data' in response) {
          
          const status = response.data.status;
          console.log(status);
          if (status === 'success') {
            toast.success(response.data.message);
            setSubscribeVal({
              email: '',
              status: ''
            });
          }
        } else {
          // Error case
          console.error('Error sending query:', response.error);
        }
      })
      .catch((error) => {
        console.error('Error sending query:', error);

      });
  }




  return (
    <>
      {/* <div className="body-17-1"> */}

      <div className="container-fluid">
        <div className="row">
          <nav className="navbar navbar-expand-md">
            <div className="container-fluid" style={{ padding: '0% 3%' }}>
              <a className="navbar-brand" href="/">
                {/* <img src="./img/logo.png" alt="" /> */}
                <img src={img54} className="img-fluid" alt="" />

              </a>
              <button
                className="navbar-toggler"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#navbarScroll"
                aria-controls="navbarScroll"
                aria-expanded="false"
                aria-label="Toggle navigation"
              >
                <span className="">
                  <i className="bi bi-list text-white" />
                </span>
              </button>




              <div className="collapse navbar-collapse" id="navbarScroll">
                <ul className="navbar-nav me-auto my-2 my-lg-0 navbar-nav-scroll ms-md-5  gap-4">
                  <li className="nav-item">
                    {/* <a
                      className="nav-link nav_active"
                      style={{ color: "#4ba5eb", fontWeight: 800 }}
                      href="/"
                    >
                      Home
                    </a> */}
                  </li>

                </ul>


                {isMobile ?

                  <div className="d-flex gap-4" role="search" style={{ justifyContent: 'center' }}>
                    {/* <button className="button_leniar px-3" type="submit"  onClick={() => {
                navigate("dashboard");
              }}>
                  Dashboard
                </button> */}
                    <button className="button_leniar px-3"
                      onClick={handleLogout} style={{ fontSize: '13px' }}>Logout</button>
                    <button className="button_leniar px-3"
                      onClick={() => window.history.back()} style={{ fontSize: '13px' }}>Go Back</button>
                  </div>
                  : <div className="d-flex gap-4" role="search">
                    {/* <button className="button_leniar px-3" type="submit"  onClick={() => {
  navigate("dashboard");
}}>
    Dashboard
  </button> */}
                    <button className="button_leniar px-3"
                      onClick={handleLogout} style={{ fontSize: '13px' }}>Logout</button>
                    <button className="button_leniar px-3"
                      onClick={() => window.history.back()} style={{ fontSize: '13px' }}>Go Back</button>
                  </div>}

              </div>
            </div>
          </nav>
          <hr className="" style={{ color: "rgb(154, 160, 163)" }} />
        </div>
      </div>

      <Box padding={2}>
        <Typography
          textAlign="center"
          variant="h4"
          sx={{
            background:
              "-webkit-linear-gradient(139deg, #036AB7 0%, #49A4EA 100%)",
            WebkitBackgroundClip: "text",
            WebkitTextFillColor: "transparent",
            letterSpacing: "-0.8px",
            fontWeight: "bold",
          }}
        >
          Settings
        </Typography>
      </Box>
      <Container>
        <Stack>
          <Box
            padding={2}
            border="1px solid #CACBCC"
            borderRadius={1}
            component="form"
            noValidate
          >


            <Grid container style={{ borderRadius: '5px' }}>
              <Grid item xs={12} md={4} lg={4} style={{ padding: '1%' }}>
                <Grid item xs={12} md={12} lg={12} style={{ padding: '1%', textAlign: 'center' }}>
                  {/* <LoadingButton

                  sx={{
                    background:
                      "linear-gradient(270deg, #4BA5EB 0%, #0168B5 100%)",
                    marginLeft: 2,
                    marginBottom: 2,
                    color: "white",
                    paddingX: "20px",
                    paddingY: "12px",
                    borderRadius: "4px",
                    width: "fit-content",
                    textAlign: 'center',
                    pointerEvents: 'none'
                  }}

                >

                  Current Plan

                </LoadingButton> */}
                  <Typography color="#CBCBCB" textAlign={'left'}
                    variant="h6"
                    fontWeight={600}>Current Plan</Typography>
                  <Typography color="#CBCBCB"
                    variant="h6" textAlign={'left'}
                    fontWeight={300}>Here is your current active plan</Typography>
                </Grid>
                <br></br>

              </Grid>

              <Grid item xs={12} md={8} lg={8} style={{ padding: '1%' }}>

                <Box style={{ backgroundColor: '#F9FBFC', padding: '1%', borderRadius: '5px' }}>

                  <Stack style={{}}>
                    <Grid container>
                      <Grid item xs={12} md={6} lg={6} style={{ padding: '1%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                        <Grid container>
                          <Grid item xs={6} md={6} lg={6} style={{ padding: '1%' }}>
                            <Typography color="#000"
                              variant="h6"
                              fontWeight={400}>Plan Details :</Typography>
                            <Typography color="#000"
                              variant="h6"
                              fontWeight={400}>Subscription Date :</Typography>
                          </Grid>
                          <Grid item xs={6} md={6} lg={6} style={{ padding: '1%' }}>

                            <Typography color="#000"
                              variant="h6"
                              fontWeight={400}>{subscriptionDate} </Typography>
                            <Typography color="#000"
                              variant="h6"
                              fontWeight={400}>{planDetails}</Typography>
                          </Grid>
                        </Grid>
                      </Grid>
                     
                      <Grid item xs={12} md={6} lg={6} style={{ padding: '1%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                 
                        {subscriptionDate && (
                          <Grid item xs={12} style={{ textAlign: isMobile ? 'center' : 'right', padding: '2%' }}>
                            <Button variant="contained" style={{ backgroundColor: '#D84D4D', width: '50%', color: '#FFF' }} onClick={handleunsubscribepayment}>Unsubscribe</Button>
                          </Grid>
                        )} 
                      </Grid>
                    </Grid>

                  </Stack>
                </Box>

              </Grid>

            </Grid>

            <Grid container style={{ borderRadius: '5px' }}>
              <Grid item xs={12} md={4} lg={4} style={{ padding: '1%' }}>


                <Grid item xs={12} md={12} lg={12} style={{ padding: '1%', textAlign: 'center' }}>
                  <Typography color="#CBCBCB" textAlign={'left'}
                    variant="h6"
                    fontWeight={600}>Change Password</Typography>
                  <Typography color="#CBCBCB" textAlign={'left'}
                    variant="h6"
                    fontWeight={300}>You can change password</Typography>
                </Grid>
                <br></br>

              </Grid>

              <Grid item xs={12} md={8} lg={8} style={{ padding: '1%' }}>

                <Box style={{ backgroundColor: '#F9FBFC', padding: '3%', borderRadius: '5px' }}>


                  <Stack>
                    <Grid container paddingY={'1%'}>

                      {/* <Grid item xs={4} md={3} lg={3} style={{ padding: '1% 0%' }}> */}
                      <Typography color="#000"
                        variant="h6"
                        fontWeight={400}>Old Password</Typography>
                      {/* </Grid> */}
                      {/* <Grid item xs={8} md={9} lg={9} style={{ padding: '1% 0%' }}> */}
                      <TextField

                        InputProps={{
                          style: customInputStyle,
                        }}
                        sx={{ width: "100%" }}
                        type="password"
                        placeholder="Enter your old Password here."
                        // onChange={handleChange}
                        onChange={(e) => setUpdateVal({ ...updateVal, oldPassword: e.target.value })}

                      />
                      {Errors.oldPassword && <p style={{ color: 'red', fontSize: '10px' }}>{Errors.oldPassword}</p>}
                      {/* </Grid> */}
                    </Grid>

                    <Grid container paddingY={'1%'}>
                      {/* <Grid item xs={4} md={3} lg={3} style={{ padding: '1% 0%' }}> */}
                        <Typography color="#000"
                          variant="h6"
                          fontWeight={400}>New Password</Typography>
                      {/* </Grid> */}
                      {/* <Grid item xs={8} md={9} lg={9} style={{ padding: '1% 0%' }}> */}
                        <TextField
                          InputProps={{
                            style: customInputStyle,
                          }}
                          sx={{ width: "100%" }}
                          type="Password"
                          placeholder="Enter new password here."
                          onChange={(e) => setUpdateVal({ ...updateVal, password: e.target.value })}
                        // onChange={handleChange}
                        />
                        {Errors.password && <p style={{ color: 'red', fontSize: '10px' }}>{Errors.password}</p>}
                      {/* </Grid> */}
                    </Grid>

                    <Grid container paddingY={'1%'}>
                      {/* <Grid item xs={4} md={3} lg={3} style={{ padding: '1% 0%' }}> */}
                        <Typography color="#000"
                          variant="h6"
                          fontWeight={400}>Retype Password</Typography>
                      {/* </Grid> */}
                      {/* <Grid item xs={8} md={9} lg={9} style={{ padding: '1% 0%' }}> */}
                        <TextField
                          InputProps={{
                            style: customInputStyle,
                          }}
                          sx={{ width: "100%" }}
                          type="Password"
                          placeholder="retype new password here."
                          onChange={(e) => setUpdateVal({ ...updateVal, passwordConfirm: e.target.value })}
                        // onChange={handleChange}
                        />
                        {Errors.passwordConfirm && <p style={{ color: 'red', fontSize: '10px' }}>{Errors.passwordConfirm}</p>}
                      {/* </Grid> */}
                    </Grid>

                    <Grid container style={{  paddingTop: '1%',paddingBottom: '1%' }}>
                      {/* <Grid item xs={12} md={9} lg={9} style={{ padding: '1%' }}> */}
                        <Grid item xs={12} md={6} lg={6} style={{  display: 'flex', paddingTop: '1%',paddingBottom: '1%' }}>
                          <Button variant="outlined" style={{ marginRight: '1%', width: '100%' }} onClick={handleCancel}>Cancel</Button>
                        </Grid>
                        <Grid item xs={12} md={6} lg={6} style={{  display: 'flex', paddingTop: '1%',paddingBottom: '1%' }}>
                          
                          <Button variant="contained" style={{ marginRight: '1%', width: '100%', backgroundColor: '#0076AD', color: '#FFF' }} onClick={handleUpdatePassword}>Update Password</Button>
                        </Grid>
                        {/* */}

                      {/* </Grid> */}
                    </Grid>
                  </Stack>
                </Box>
              </Grid>

            </Grid>
            <Divider />


            <Grid container >
              <Grid item xs={12} md={12} lg={12} style={{ padding: '1%', textAlign: 'center', borderRadius: '5px', margin: '1% 0%' }}>
                <Typography color="#CBCBCB"
                  variant="h6" textAlign={'left'}
                  fontWeight={600}>Payment History</Typography>
              </Grid>
            </Grid>
            {isBrowser ?
              <TableContainer component={Paper} sx={{ margin: '1%', width: '98%' }}>
                <Table sx={{ minWidth: 700 }} aria-label="customized table">
                  <TableHead style={{ backgroundColor: ' #F2F6FA', border: "gray" }}>
                    <TableRow>
                      <StyledTableCell style={{ border: "gray" }} align="center">S.No</StyledTableCell>
                      <StyledTableCell style={{ border: "gray" }} align="center">Invoice No</StyledTableCell>
                      <StyledTableCell style={{ border: "gray" }} align="center">Amount</StyledTableCell>
                      <StyledTableCell style={{ border: "gray" }} align="center">Payment Status</StyledTableCell>
                      <StyledTableCell style={{ border: "gray" }} align="center">Payment Date</StyledTableCell>
                    </TableRow>
                  </TableHead>

                  <TableBody>
                    {
                      paymentList ?
                        paymentList?.map((payment, index) => (
                          <StyledTableRow key={index}>
                            <StyledTableCell style={{ color: 'black', border: "darkgray" }} align="center">{payment.serialNumber}</StyledTableCell>
                            {/* <StyledTableCell component="th" scope="row" align="left" style={{ color: 'black', border: "darkgray" }}>{'1'}
                                        </StyledTableCell> */}
                            <StyledTableCell style={{ color: 'black', border: "darkgray" }} align="center">{payment.invoice_no}</StyledTableCell>
                            <StyledTableCell style={{ color: 'black', border: "darkgray" }} align="center">{Number(payment.amount) / 100}</StyledTableCell>
                            <StyledTableCell style={{ color: 'black', border: "darkgray" }} align="center">{payment.payment_status === 'paid' ? <span style={{backgroundColor: '#388431', borderRadius: '15px', padding: '3% 14%', color: '#FFF'}}>{payment.payment_status}</span> : <span style={{backgroundColor: '#D84D4D', borderRadius: '15px', padding: '3% 14%', color: '#FFF'}}>{payment.payment_status}</span>}</StyledTableCell>
                            <StyledTableCell style={{ color: 'black', border: "darkgray" }} align="center">{payment.payment_date}</StyledTableCell>
                          </StyledTableRow>
                        ))
                        :    
                        <StyledTableRow>
                          <StyledTableCell colSpan={6} style={{ color: 'black', border: "darkgray" }} align="center">{'No payment history available.'}</StyledTableCell>
                        </StyledTableRow>
                    }

                  </TableBody>

                </Table>
              </TableContainer>
              : null}

            {/* MobileView */}
            {isMobile ?
              <Box style={{ backgroundColor: '#F9FBFC', height: '100%', padding: '1%', borderRadius: '5px' }}>
                {
                  paymentList ?
                    paymentList?.map((payment, index) => (
                      <Stack>
                        <Grid container>
                          <Grid item xs={5} md={5} lg={5} style={{ padding: '1%' }}>
                            <Typography color="#000"
                              variant="h6"
                              fontWeight={900}>S.No:</Typography>
                          </Grid>
                          <Grid item xs={7} md={7} lg={7} style={{ padding: '1%' }}>
                            <Typography color="#000"
                              variant="h6"
                              fontWeight={400}>{payment.serialNumber}</Typography>
                          </Grid>
                        </Grid>

                        <Grid container>
                          <Grid item xs={5} md={5} lg={5} style={{ padding: '1%' }}>
                            <Typography color="#000"
                              variant="h6"
                              fontWeight={900}>Invoice No:</Typography>
                          </Grid>
                          <Grid item xs={7} md={7} lg={7} style={{ padding: '1%', wordWrap: 'break-word' }}>
                            <Typography color="#000"
                              variant="h6"
                              fontWeight={400}>{payment.invoice_no}</Typography>
                          </Grid>
                        </Grid>

                        <Grid container>
                          <Grid item xs={5} md={5} lg={5} style={{ padding: '1%' }}>
                            <Typography color="#000"
                              variant="h6"
                              fontWeight={900}>Amount:</Typography>
                          </Grid>
                          <Grid item xs={7} md={7} lg={7} style={{ padding: '1%' }}>
                            <Typography color="#000"
                              variant="h6"
                              fontWeight={400}>{Number(payment.amount) / 100}</Typography>
                          </Grid>
                        </Grid>

                        <Grid container>
                          <Grid item xs={5} md={5} lg={5} style={{ padding: '1%' }}>
                            <Typography color="#000"
                              variant="h6"
                              fontWeight={900}>Payment Status:</Typography>
                          </Grid>
                          <Grid item xs={7} md={7} lg={7} style={{ padding: '1%' }}>
                            <Typography color="#000"
                              variant="h6"
                              fontWeight={400}>{payment.payment_status === 'paid' ? <span style={{backgroundColor: '#388431', borderRadius: '15px', padding: '3% 14%', color: '#FFF'}}>{payment.payment_status}</span> : <span style={{backgroundColor: '#D84D4D', borderRadius: '15px', padding: '3% 14%', color: '#FFF'}}>{payment.payment_status}</span>}</Typography>
                          </Grid>
                        </Grid>

                        <Grid container>
                          <Grid item xs={5} md={5} lg={5} style={{ padding: '1%' }}>
                            <Typography color="#000"
                              variant="h6"
                              fontWeight={900}>Payment Date:</Typography>
                          </Grid>
                          <Grid item xs={7} md={7} lg={7} style={{ padding: '1%' }}>
                            <Typography color="#000"
                              variant="h6"
                              fontWeight={400}>{payment.payment_date}</Typography>
                          </Grid>
                        </Grid>
                        <Divider />
                      </Stack>

                    )) : <Stack>
                      <Grid container>
                        <Grid item xs={12} md={12} lg={12} style={{ padding: '1%' }}>
                          <Typography color="#000"
                            variant="h6"
                            fontWeight={400}>No Payment History Available</Typography>
                        </Grid>
                      </Grid>
                    </Stack>}
              </Box> : null}

            {/*  */}
            {paymentList ?
              <div style={{ textAlign: 'center' }}>
                <LoadingButton
                  startIcon={
                    <SvgIcon>
                      <KeyboardArrowLeft />
                    </SvgIcon>
                  }

                  sx={{
                    paddingRight: '15px'
                  }}
                  onClick={handlePrevPage}
                  disabled={currentPage === 1}
                >

                </LoadingButton>

                {/* <span>{currentPage}</span> */}
                {pageNumbers.map((page, index) => (
                  <span key={index} style={{ padding: '0 5px' }}>
                    <span
                      style={{
                        cursor: page === '...' ? 'default' : 'pointer',
                        fontWeight: page === currentPage ? 'bold' : 'normal'
                      }}
                      onClick={() => {
                        if (typeof page === 'number') setCurrentPage(page);
                      }}
                    >
                      {page}
                    </span>
                  </span>
                ))}

                <LoadingButton
                  startIcon={
                    <SvgIcon>
                      <KeyboardArrowRight />
                    </SvgIcon>
                  }

                  sx={{
                    paddingLeft: '35px'
                  }}
                  onClick={handleNextPage}
                  disabled={paymentList && paymentList?.length < pageSize}
                >

                </LoadingButton>
              </div>
              : null}
          </Box>
        </Stack >
      </Container >
      {/* </div> */}

     {/*  <footer>
      <div className="py-5 mx-sm-0 mx-2 backgroundbg">
      <div className="container">
        <div className="row px-sm-0 px-2">
          <div className="col-lg-3 text-white text-lg-start text-center">
            <div>
              <div>
                <img src={img54} className="img-fluid" alt="" />

              </div>
              <p className="fw_samibold">
                OceSha/Code Genie Los Angeles, Ca 310-748-8901
              </p>
            </div>
          </div>
          <div className="col-lg-3 text-white d-lg-none d-block  text-lg-start text-center">
            <div>
              <h6 className="f_heading mb-4">
                Get news in your mailbox right away!
              </h6>
              <p className="fw_samibold">
                In addition, stay in loop for our exclusive launches and limited
                time deals.
              </p>
            </div>
            <div className="input-group mb-3">
              <input type="text" className="form-control" placeholder="Search" />
              <button className="btn btn-primary" type="submit">
                Subscribe
              </button>
            </div>
          </div>
          <div className="col-lg-2 col-sm-4">
            <div>
              <div className="text-white">
                <h6 className="fw_samibold">Products</h6>
                <ul
                  className="ps-0 mt-3 footer_dec"
                  style={{ listStyle: "none" }}
                >
                  <li>
                    <a target="_blank" href="https://ai-course.code-genie.ai/courses/ArtificialIntelligence">AI Course</a>
                  </li>
                  <li>
                    <a target="_blank" href="https://consulting.code-genie.ai/">AI Consulting</a>
                  </li>
                  <li>
                    <a target="_blank" href="https://www.amazon.com/TECH-WARZ-Rise-Artificial-Intelligence-ebook/dp/B0C7BPRX9J/ref=sr_1_1?qid=1700062906&refinements=p_27%3ARohan+Hall&s=books&sr=1-1">AI Book</a>
                  </li>
                  <li>
                    <a target="_blank" href="https://www.amazon.com/TECH-WARZ-BLOCKCHAIN-CRYPTOCURRENCY-Money-ebook/dp/B0C83TGQSY/ref=sr_1_2?qid=1700062906&refinements=p_27%3ARohan+Hall&s=books&sr=1-2">Blockchain Book</a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div className="col-lg-2 col-sm-4 text-lg-start text-sm-center">
            <div>
              <div className="text-white">
                <h6 className="fw_samibold">Information</h6>
                <ul
                  className="ps-0 mt-3 footer_dec"
                  style={{ listStyle: "none" }}
                >
                  <li>
                      <a target="_blank" href="/aboutus">About Us</a>
                    </li>
                    <li>
                      <a target="_blank" href="https://codegenie.getrewardful.com/signup?_gl=1*wfwhnc*_gcl_au*MTMwOTQ5ODcxNy4xNjkyODU4NDk3*_ga*MTA4OTc3NTMyNC4xNjkyODUzODcz*_ga_YJYFH7ZS27*MTY5Mjg3OTg0NS40LjEuMTY5Mjg4NjM1MC41Mi4wLjA.&_ga=2.74546258.159718116.1692853906-1089775324.1692853873">Affiliate</a>
                    </li>
                    <li>
                      <a target="_blank" href="/contactus">Contact us</a>
                    </li>
                </ul>
              </div>
            </div>
          </div>
          <div className="col-lg-2 col-sm-4  text-lg-start text-sm-end">
            <div>
              <div className="text-white">
                <h6 className="fw_samibold">Support</h6>
                <ul
                  className="ps-0 mt-3 footer_dec"
                  style={{ listStyle: "none" }}
                >
                  <li>
                      <a target="_blank" href="/terms">Terms and Conditions</a>
                    </li>
                    <li>
                      <a target="_blank" href="/privacy">Privacy &amp; Cookie policy</a>
                    </li>
                </ul>
              </div>
            </div>
          </div>
          <div className="col-lg-3 text-white d-lg-block d-none">
            <div>
              <h5 className="mb-4 f_heading">
                Get news in your mailbox right away!
              </h5>
              <p className="fw_samibold">
                In addition, stay in loop for our exclusive launches and limited
                time deals.
              </p>
            </div>


         <div className="input-group mb-3" onSubmit={handleSubmit(onSubmitHandler)}>
                    <input className={`form-control ${errors["email"] ? "error" : ""}`}
                      {...register("email")}
                      type="email"
                      placeholder="Enter your mail"
                      onChange={(e) => setSubscribeVal({ ...subscribeVal, email: e.target.value })} />
                    <button className="btn btn-primary" type="submit" onClick={handleSubscribeNews}>
                      Subscribe
                    </button>
                    {errors["email"] && (
                      <span className="error-message">{errors["email"]?.message}</span>
                    )}
                  </div>


          </div>
        </div>
      </div>
    </div>
    </footer> */}

    </>
  );
};

export default Usersetting;