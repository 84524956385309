import { useState, useContext, useEffect, useMemo } from "react";
import {
  Box,
  Toolbar,
  IconButton,
  Typography,
  Menu,
  Container,
  Button,
  MenuItem,
  SvgIcon,
  FormControlLabel,
  Switch,
  Divider,
} from "@mui/material";
import { Menu as MenuIcon } from "@mui/icons-material";
import { styled } from "@mui/material/styles";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { useCookies } from "react-cookie";

import { ReactComponent as Logo } from "../../logo.svg";
import { ReactComponent as CrownIcon } from "../../assets/ico_crown.svg";
import { ReactComponent as ProductIcon } from "../../assets/ico_product.svg";
import { ReactComponent as DownIcon } from "../../assets/ico_downarr.svg";
import { getDesignTokens, ColorModeContext } from "../../theme";
import { useLazyGetModulesQuery } from "../../redux/api/productApi";
import { useAppDispatch } from "../../redux/store";
import { setModule } from "../../redux/features/genieSlice";
import { logout } from "../../redux/features/userSlice";

import { createTheme, ThemeProvider } from "@mui/material/styles";
import { PaletteMode } from "@mui/material";




function PricingHeader() {
  const [cookies, setCookie, removeCookie] = useCookies(["logged_in"]);
  const [anchorElNav, setAnchorElNav] = useState<null | HTMLElement>(null);
  const colorMode = useContext(ColorModeContext);

  const [modules, setModules] = useState<string[]>([]);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const [mode, setMode] = useState<PaletteMode>("dark");

  const theme1 = useMemo(() => createTheme(getDesignTokens(mode)), [mode]);

  const userRole = localStorage.getItem('userRole');
  console.log('role of the user2=>' + userRole);



  const colorMode1 = useMemo(
    () => ({
      
        toggleColorMode: () => {
          {userRole === 'admin' ? 
          setMode((prevMode: PaletteMode) =>
            prevMode === "light" ? "light" : "light"
          ) : setMode((prevMode: PaletteMode) =>
          prevMode === "light" ? "dark" : "dark"
        )}
      },
    }),
    []
  );

  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const [getModules, getState] = useLazyGetModulesQuery();


  useEffect(() => {
    getModules("CodeGenie");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (getState.isSuccess)
      setModules(getState.data.filter((item) => item !== ""));
  }, [getState]);

  const handleOpenNavMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElNav(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const handleLogout = () => {
    removeCookie("logged_in");
    dispatch(logout());
    window.location.href = "https://www.rohanhall.com/code-genie-ai-smart-contract-auditor";
    toast.success("Logout success");
    localStorage.removeItem("payment");
    localStorage.removeItem("msg");
    localStorage.removeItem("complexity");
    localStorage.removeItem("module");
    localStorage.removeItem("subscription_level");
    localStorage.removeItem("free_plan");
    localStorage.removeItem("userRole");
    
  }
 
  return (
    <>
    <ColorModeContext.Provider value={colorMode1}>
    <ThemeProvider theme={theme1}>
      <Container maxWidth="xl">
        <Toolbar disableGutters>
          <SvgIcon
            sx={{
              mr: 1,
              width: 44,
              height: 44,
            }}
          >
            <Logo />
          </SvgIcon>
          <Typography
            variant="h6"
            noWrap
            component="a"
            href="/"
            sx={{
              mr: 2,
              display: { xs: "none", md: "flex" },
              fontWeight: 700,
              color: "inherit",
              textDecoration: "none",
            }}
            onClick={(event) => {
              event.preventDefault();
              navigate("/");
            }}
          >
            CodeGenie
          </Typography>

          <Typography
            variant="h5"
            noWrap
            component="a"
            href=""
            sx={{
              mr: 2,
              display: { xs: "flex", md: "none" },
              flexGrow: 1,
              fontWeight: 700,
              color: "inherit",
              textDecoration: "none",
            }}
          >
            CodeGenie
          </Typography>
          <Box sx={{ display: { xs: "flex", md: "none" } }}>
            <IconButton
              size="large"
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleOpenNavMenu}
              color="inherit"
            >
              <MenuIcon />
            </IconButton>
            <Menu
              id="menu-appbar"
              anchorEl={anchorElNav}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "left",
              }}
              keepMounted
              transformOrigin={{
                vertical: "top",
                horizontal: "left",
              }}
              open={Boolean(anchorElNav)}
              onClose={handleCloseNavMenu}
              sx={{
                display: { xs: "block", md: "none" },
              }}
            ></Menu>
          </Box>
          <Box
            sx={{
              flexGrow: 1,
              display: { xs: "none", md: "flex" },
              ml: 12,
              gap: 6,
            }}
          >
          </Box>

          {/* <Box sx={{ flexGrow: 0, display: { xs: "none", md: "block" } }}>
            {!(localStorage.getItem("logged_in") == "true") && (
              <>
                <Button
                  sx={{
                    background: "linear-gradient(270deg, #4BA5EB 0%, #0168B5 100%)",
                    marginLeft: 5,
                    color: "white",
                    paddingX: 2,
                    paddingY: 1,
                  }}
                  onClick={() => {
                    localStorage.setItem("module", "SMART CONTRACTS");
                    dispatch(setModule("SMART CONTRACTS"));
                    navigate("/signup");
                  }}
                  >
                  Try Now
                </Button>
                <Button
                  sx={{
                    background: "linear-gradient(270deg, #4BA5EB 0%, #0168B5 100%)",
                    marginLeft: 5,
                    color: "white",
                    paddingX: 2,
                    paddingY: 1,
                  }}
                  onClick={() => {
                    navigate("/signin");
                  }}
                  >
                  Sign in
                </Button>
              </>
            )}
            
            {(localStorage.getItem("logged_in") == "true") && (
              <>
               <Button
                  sx={{
                    background: "linear-gradient(270deg, #4BA5EB 0%, #0168B5 100%)",
                    marginLeft: 5,
                    color: "white",
                    paddingX: 2,
                    paddingY: 1,
                  }}
                  onClick={() => {
                    
                    navigate("/");
                }}
                >
                  Home
                </Button>
                <Button
                  sx={{
                    background: "linear-gradient(270deg, #4BA5EB 0%, #0168B5 100%)",
                    marginLeft: 5,
                    color: "white",
                    paddingX: 2,
                    paddingY: 1,
                  }}
                  onClick={() => {
                    localStorage.setItem("module", "SMART CONTRACTS");
                    dispatch(setModule("SMART CONTRACTS"));
                    navigate("/codegenie/smart_contracts");
                }}
                >
                  Dashboard
                </Button>
                <Button
                  sx={{
                    background: "linear-gradient(270deg, #4BA5EB 0%, #0168B5 100%)",
                    marginLeft: 5,
                    color: "white",
                    paddingX: 2,
                    paddingY: 1,
                  }}
                  onClick={handleLogout}
                >
                  Logout
                </Button>
              </>
            )}
          </Box> */}
        </Toolbar>
      </Container>
      <Divider />
    </ThemeProvider>
    </ColorModeContext.Provider>
    </>
  );
}
export default PricingHeader;
